import React, { useState } from 'react'
import WTPSideBar from './WTPSideBar'
import "./CreateCompaignModal.css"
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'
import Backend_Url from '../../config/config'
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie'

const CreateCompaignModal = ({ closeWindow }) => {
    const [image, setimage] = useState(null)
    const [selectedimage, setselectedimage] = useState(null)
    const [caption, setcaption] = useState(null)
    const [cookies] = useCookies(['token']);
    // --------------------------------onselectr ----------------------------------------------------
    const onSelectImage = async (e) => {
        try {
            debugger
            const file = e.target.files[0];
            setimage(file)
            if (file) {
                // Create a URL for the selected file
                const url = URL.createObjectURL(file);
                setselectedimage(url); // Set the image URL in state
            }
        } catch (error) {
            console.log(error);
        }
    };




    // ------------------------------handlesubmit--------------------------------------------
    const handleSubmit = async (e) => {
        try {
            debugger
            e.preventDefault();
            const formdata = new FormData();
            formdata.append("file", image)
            formdata.append("caption", caption)
            await axios.post(`${Backend_Url}/post/instaupload`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookies.token}`
                }
            }).then((response) => {
                console.log(response.data)
                Swal.fire({
                    title: 'Success!',
                    text: 'Your post has been successfully submitted!',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                });
                closeWindow(false);
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error submitting your post.',
                icon: 'error',
                confirmButtonText: 'Try Again',
            });
        }
    }

    return (
        <div className="Flex-Head-Right-Compaigns">
            {/* <div>
                <WTPSideBar />
            </div> */}
            <div className={`container`}>
                <form onSubmit={handleSubmit} className={`main-form`}>
                    <div className={`form-left-side`}>
                        {/* -----------------select_image-------------------- */}
                        {selectedimage == null ?
                            <div className={`selected-image w-full h-full bg-gray-300`}>
                                <p className='!bg-gray-300' style={{ position: 'absolute' }}>
                                    <Icon icon="ph:plus-fill" style={{ color: "black", width: '30px', height: '30px' }} />
                                    Select Image
                                </p>
                                <input type='file' style={{ opacity: '0', width: '100%', height: '100%', }} onChange={onSelectImage} placeholder='Select Image' />

                            </div>

                            : <img src={selectedimage} className={`selected-image`} alt="Selected" style={{ width: '100%', height: '100%', overflow: 'hidden', objectFit: 'cover' }} />
                        }

                        {
                            selectedimage != null &&
                            <button onClick={() => setselectedimage(null)} className={` w-auto h-auto absolute bottom-[15px] bg-gray-300  p-2 left-[15px]`}>
                                <Icon icon="mingcute:delete-fill" style={{ color: "black" }} />
                            </button>
                        }
                    </div>
                    <div className={`form-right-side`}>
                        {/* -----------select_temp----------------- */}
                        {/* <div className={`div-for-form`}>
                            <label>Select Template</label>
                            <select>
                                <option>--select---</option>
                                <option>--select---</option>
                                <option>--select---</option>
                                <option>--select---</option>
                            </select>
                        </div> */}
                        {/* -----------select gruop----------------- */}
                        {/* <div className={`div-for-form`}>
                            <label>Select Gruop</label>
                            <select>
                                <option>--select---</option>
                                <option>--select---</option>
                                <option>--select---</option>
                            </select>
                        </div> */}
                        {/* ---------------------contacts----------------------- */}
                        <div className={`div-for-form`}>
                            <label className={`flex items-center justify-center gap-2 py-[10px]`}>Add caption <Icon icon="f7:captions-bubble" style={{ width: "15px", height: "15px", color: "black" }} /></label>
                            <textarea className={`!text-gray-800 !max-h-[100px] border border-spacing-1 border-gray-500`} onChange={(e) => setcaption(e.target.value)} style={{ padding: '10px' }} placeholder='write caption...'>
                            </textarea>
                        </div>
                        {/* ------------submit button------------------- */}
                        <div className={`button-div`} >
                            <button onClick={() => closeWindow(false)} className={`cancel`}>
                                Cancel
                            </button>
                            <button type='submit'>
                                Post
                            </button>
                        </div>
                    </div>

                </form>
                <div onClick={() => closeWindow(false)} className={`backside`}></div>
            </div>
        </div>
    )
}

export default CreateCompaignModal