import React, { useContext, useEffect, useState } from 'react'
import "./Dashboard.css"
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar'
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'
import Backend_Url from '../../config/config'
import { useCookies } from 'react-cookie'
import MyContext from '../../context/Mycontext'
import CreateCompaignModal from '../WhatsappCampaigns/CreateCompaignModal'

const Dashboard = () => {
    const [cookies] = useCookies(['token']);
    const [totalContact, settotalContact] = useState(0);
    const [deliverdCount, setdeliverdCount] = useState(0);
    const [successfullyaccepted, setsuccessfullyaccepted] = useState([]);
    const [unsuccessfullymsg, setunsuccessfullymsg] = useState([]);
    const [totalsendmsg, settotalsendmsg] = useState([]);
    const [totaldelivery, settotaldelivery] = useState([]);
    const { messagedeliverd, setmessagedeliverd } = useContext(MyContext)
    const [] = useState(null);

    const data = [
        {
            count: totalContact,
            label: 'Total Contact',
            icon: (
                <>
                    <Icon icon="ic:round-people" style={{ color: "black" }} />
                </>
            )

        },
        {
            count: successfullyaccepted.length,
            label: 'Total Accepted',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        },
        {
            count: unsuccessfullymsg.length,
            label: 'Total Failed',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        },
        {
            count: totaldelivery.length,
            label: 'Total Deliverd',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        }
    ]

    const otherappid = [
        {
            appname: 'instagram',
            icon: <Icon icon="ri:instagram-fill" style={{ color: "white" }} />
        },
        {
            appname: 'facebook',
            icon: <Icon icon="mingcute:facebook-fill" style={{ color: "white" }} />
        },
        {
            appname: 'messenger',
            icon: <Icon icon="fe:messanger" style={{ color: "white" }} />
        },
    ]

    function convertDateToDDMMYYYY(isoDate) {
        const date = new Date(isoDate); // Create a Date object from the ISO string

        // Get day, month, and year
        const day = String(date.getUTCDate()).padStart(2, '0'); // Get day and pad with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad
        const year = date.getUTCFullYear(); // Get full year

        // Return formatted date in dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }

    const tabeldata = totalsendmsg.map((item) => {
        console.log(item)
        return ({
            Date: convertDateToDDMMYYYY(item.createdAt),
            Campaing_ID: item.campaignId,
            Campaing_Name: item.campaign_name,
            Owner_Name: item.campaignOwner,
            Template_Name: item.templateName,
            Status: item.wa_message_accept,
            Contacts: item.contact_number,
            Sent: successfullyaccepted.length,
            Report: 'View'
        })
    })

    // const tabeldata = [
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },
    //     {
    //         Date: '27/09/2024',
    //         Campaing_Name: 'Image sent',
    //         Owner_Name: 'Mothershut',
    //         Status: 'Deliverd',
    //         Contacts: '804534535',
    //         Sent: '320',
    //         Report: 'View'
    //     },

    // ]

    // --------------------------------fetch data --------------------------------
    const fetallcontact = async (e) => {
        try {
            debugger
            await axios.get(`${Backend_Url}/contacts/contacts`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res)
                settotalContact(res.data.length)
            })
        } catch (error) {
            console.log(error)
        }
    }


    // ---------------------------fetch accepted count -----------------------------

    const fetchaccepted = async (compaingid = "4b3d0c3d") => {
        try {

            await axios.get(`${Backend_Url}/messagesstatus/findByid/${compaingid}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res.data)
                // const responsedata = res.data.data
                setsuccessfullyaccepted(res.data.data.filter((item) => item.wa_message_accept == 'accepted'))
                setunsuccessfullymsg(res.data.data.filter((item) => item.wa_message_accept != 'accepted'))
                settotaldelivery(res.data.data)
            });

        } catch (error) {
            console.log(error)
        }
    }

    const fetchtotal = async (e) => {
        try {
            await axios.get(`${Backend_Url}/messagesstatus/findBytype/4`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res.data)
                // const responsedata = res.data.data
                settotalsendmsg(res.data.data)
            });

        } catch (error) {
            console.log(error)
        }
    }


    const [modalforreport, setmodalreport] = useState(false);
    const openModalforreport = () => {
        setmodalreport(!modalforreport)
    }

    const [messagedata, setmessagedata] = useState('hello world');
    const modaldata = [
        {
            count: 90,
            label: 'Total Number',
            percentage: '55%'
        },
        {
            count: 90,
            label: 'Total Number',
            percentage: '55%'
        },
        {
            count: 90,
            label: 'Total Number',
            percentage: '55%'
        },
        {
            count: 90,
            label: 'Total Number',
            percentage: '55%'
        }
    ]

    const [openinsta, setopeninsta] = useState(false);
    const openinstamodal = () => {
        setopeninsta(!openinsta)
    }

    // --------------------------------retrive deliverd count ---------------------
    useEffect(() => {
        fetallcontact()
        fetchaccepted()
        fetchtotal()
        console.log("messagedeliverd", messagedeliverd);
    }, [])


    const handlecampaignfill = (campid) => {
        fetchaccepted(campid)
    }

    useEffect(() => {
        console.log(totalContact);
    }, [totalContact]);
    return (
        <>
            <div className="Flex-Head-Right-Compaigns">
                <div>
                    <WTPSideBar />
                </div>
                <div className="Right-Campaigns-Body">
                    {/* -------------top-------------------- */}
                    <div className={` w-full flex items-center justify-start flex-col`}>
                        <div className={`flex items-center bg-[#f8f9fa] justify-between py-[10px] px-[15px] w-full`}>
                            <p className={` text-[17px] font-bold`}>Dashboard</p>
                            <div className={`w-[35px] h-[35px] rounded-full overflow-hidden`}>
                                {/* <span className={`w-full  h-full rounded-full flex items-center justify-center bg-[#1dc31d]`}>
                                    <Icon icon="fa:hashtag" style={{ color: "white" }} />
                                </span> */}
                            </div>
                        </div>
                        <div className={`w-full h-auto flex items-center justify-around bg-[#f8f9fa] py-[15px] `}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <section className={`w-[22%] min-h-[50px] ${index == 1 && 'bg-[#d9d9d9]'} ${index == 0 && 'bg-pink-100'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-yellow-100'} py-[20px] flex items-center justify-start rounded-[5px]`}>
                                                <div className={`w-[25%] text-3xl flex items-center justify-center h-full`}>
                                                    {item.icon}
                                                </div>
                                                <div className={`w-[70%] h-[50px] flex items-start justify-center flex-col`}>
                                                    <h2 className={`w-full h-auto text-[25px] font-[700]`}>{item.count}</h2>
                                                    <p className={`text-[15px] font-[500]`}>{item.label}</p>
                                                </div>
                                            </section>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* -----------------------other app id--------------------------------------------------- */}
                    <div className={`w-full min-h-[50px] flex items-center bg-[#dfdfdf] justify-start gap-[40px] py-[15px] px-[19px]`}>
                        {
                            otherappid.map((item) => {
                                return (
                                    <>
                                        <div onClick={() => openinstamodal()} className={`w-auto cursor-pointer ${item.appname == 'instagram' ? 'bg-purple-400 text-white font-[400]' : null} ${item.appname == 'facebook' ? 'bg-blue-400 text-white font-[400]' : null} ${item.appname == 'messenger' ? 'bg-blue-700 text-white font-[400]' : null}cursor-pointer gap-[10px] bg h-auto py-[5px] shadow-md rounded-md flex items-center justify-start px-[10px]`}>
                                            <span className={`w-auto text-white h-auto flex items-center justify-center`}>
                                                {item.icon}
                                            </span>
                                            <p className={`w-auto text-[15px]`}>
                                                {item.appname}
                                            </p>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    {/* ---------------------dashboard row-------------------------------------- */}
                    <div className={`w-full h-auto flex items-start justify-center bg-[#f8f9fa] flex-col`}>
                        <div className={`w-full py-[15px] px-[10px] h-auto`}>
                            <h2 className={`w-auto h-auto text-[16px] font-[600] flex items-center justify-start`}>Campaigns Details</h2>
                        </div>
                        <div className={`w-full py-[13px] px-[16px] bg-[] h-auto`}>
                            <table className={`w-full `}>
                                <thead className={`bg-[#f8f9fa] text-black`}>
                                    <tr>
                                        <th className={` border border-spacing-1 border-[#d9d9d9]`}>S.No</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Date</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Campaign_Id</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Campaing_Name</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Owner Name</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Template Name</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Status</th>
                                        {/* <th className=' border border-spacing-1 border-[#d9d9d9]'>Contacts</th> */}
                                        {/* <th className=' border border-spacing-1 border-[#d9d9d9]'>Sent</th> */}
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Details</th>
                                        <th className=' border border-spacing-1 border-[#d9d9d9]'>Report</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabeldata.map((item, index) => (
                                            <tr className={``} key={index}>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{index + 1}</td>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Date}</td>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Campaing_ID}</td>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Campaing_Name}</td>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Owner_Name}</td>
                                                <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Template_Name}</td>
                                                <td className=' border flex items-center justify-center gap-1 border-spacing-1 border-[#d9d9d9]' style={{ color: `${item.Status == 'accepted' ? 'green' : 'red'}` }}>
                                                    {item.Status}
                                                    {item.Status == 'accepted' ? <Icon icon="fa:send-o" style={{ color: "green" }} /> : <Icon icon="ic:round-sms-failed" style={{ color: "red" }} />}
                                                </td>
                                                {/* <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Contacts}</td> */}
                                                {/* <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Sent}</td> */}
                                                <td onClick={() => handlecampaignfill(item.Campaing_ID)} className=' border border-spacing-1 border-[#d9d9d9] cursor-pointer text-yellow-700'>Show</td>
                                                <td onClick={() => openModalforreport()} className=' border border-spacing-1 border-[#d9d9d9] text-blue-400 hover:border-b cursor-pointer hover:border-b-blue-400'>{item.Report}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                modalforreport && (
                    <div className={`flex w-full h-full z-[910] items-center justify-center fixed top-0 left-0`}>
                        <div className={`w-[70%] z-[920] h-[75%] bg-white rounded-md`}>
                            <div className={`flex items-center bg-[#f8f9fa] justify-between py-[10px] px-[15px] w-full`}>
                                <p className={` text-[17px] font-bold`}>Campaign Details</p>
                                {/* <div className={`w-[35px] h-[35px] rounded-full overflow-hidden`}>
                                    <span className={`w-full  h-full rounded-full flex items-center justify-center bg-[#1dc31d]`}>
                                        <Icon icon="fa:hashtag" style={{ color: "white" }} />
                                    </span>
                                </div> */}
                            </div>

                            <div className={`flex items-center bg-[#f8f9fa] justify-center flex-col h-auto py-[20px] px-[15px] w-full`}>
                                <div className={`w-full h-auto flex items-start justify-center flex-col`}>
                                    <div className={`w-full px-[10px] gap-2 flex items-center justify-start`}>
                                        <h2 className={` font-[500]`}>Campaign Details:</h2>
                                        <p className={`text-[15px] font-[400]`}>{'test'}</p>
                                    </div>
                                    <div className={`w-full px-[10px] gap-2 flex items-center justify-start`}>
                                        <h3 className={` font-[500]`}>Send Date:</h3>
                                        <p className={`text-[15px] font-[400]`}>12/10/2024</p>
                                    </div>
                                    <div className={`w-full px-[10px] gap-2 flex items-center justify-start`}>
                                        <h3 className={` font-[500]`}>Owner Name:</h3>
                                        <p className={`text-[15px] font-[400]`}>Dummy</p>
                                    </div>
                                </div>

                                {/* -------------------show data------------------------------ */}
                                <div className={`w-full h-auto flex items-center justify-around flex-row py-[20px]`}>
                                    {
                                        modaldata.map((item, index) => {
                                            return (
                                                <>
                                                    <section className={`w-[22%] min-h-[50px] ${index == 1 && 'bg-[#d9d9d9]'} ${index == 0 && 'bg-pink-100'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-yellow-100'} py-[20px] flex items-center justify-start rounded-[5px]`}>
                                                        <div className={`w-[25%] rounded-full py-[10px] m-auto bg-white text-[15px] flex items-center justify-center h-auto`}>
                                                            {item.percentage}
                                                        </div>
                                                        <div className={`w-[70%] h-[50px] flex items-start justify-center flex-col`}>
                                                            <h2 className={`w-full h-auto text-[18px] font-[100]`}>{item.count}</h2>
                                                            <p className={`text-[15px] font-[500]`}>{item.label}</p>
                                                        </div>


                                                    </section>
                                                </>
                                            )
                                        })
                                    }
                                </div>


                                {/* -------------------------message-------------------------------- */}
                                <div className={`w-full py-[15px] px-[20px] flex items-start justify-center flex-col`}>
                                    <h2 className={`font-[500] text-[15px]`}>Campaign message</h2>
                                    <p className={`bg-gray-200 w-full h-auto py-[10px] px-[15px] rounded-md `}>
                                        {messagedata}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => setmodalreport(false)} className={`w-full bg-[#00000047] h-full absolute top-0 left-0`}></div>
                    </div>
                )
            }


            {
                openinsta && <CreateCompaignModal closeWindow={setopeninsta} />
            }

        </>
    )
}

export default Dashboard