import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../css/WTPSideBar.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import logo from "../../Assets/logo/logo.png";
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import Backend_Url from '../../config/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import MyContext from '../../context/Mycontext';
import CreateCompaignModal from './CreateCompaignModal';



const WTPSideBar = () => {
    const [cookies] = useCookies(['token']);
    const [compId, setCompId] = useState("");
    const [hookURL, setHookURL] = useState();
    const [toggle, setToggle] = useState();
    const location = useLocation();

    console.log(compId)
    const { lstmessageData } = useContext(MyContext)




    const hasUnreadMessages = (messages) => {
        debugger
        // Check if any message has an unreadCount greater than 0
        return messages ? messages.some(message => message.unreadCount > 0) : null;
    };

    // Determine the state
    useEffect(() => {
        if (lstmessageData) {

            const state = hasUnreadMessages(lstmessageData);
            setToggle(state)

        }
    }, [lstmessageData])

    // console.log(state);

    const fetchCompDetails = async () => {
        const token = cookies.token;

        try {
            const decodedToken = jwtDecode(token);
            const companyCode = decodedToken.companyCode;
            const response = await axios.get(`${Backend_Url}/companydetails/getbycomp/${companyCode}`);
            if (response.data) {
                console.log(response);
                setCompId(response.data.id)
            }
        } catch (error) {
            console.error(error);
        }
    };
    const fetchData = async (id) => {
        try {

            const response = await axios.get(`${Backend_Url}/whatsapp/v1/getURL/${id}`);
            if (response) {
                console.log(response)
                setHookURL(response.data.HookURL)
            }
            // Handle response data here
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCompDetails()
    }, []);


    useEffect(() => {
        if (compId) {
            fetchData(compId);
        }
    }, [compId]);

    const handleURL = () => {
        // alert("Hook URL: " + hookURL)

        Swal.fire({
            title: 'Hook URL',
            text: hookURL,
            icon: 'success',
            confirmButtonText: 'Copy To Clipboard',
            background: '#f9f9f9',
            color: '#333',
            confirmButtonColor: '#3085d6',
            // footer: '<a href="#">Why do I see this?</a>'
            preConfirm: () => {

                return new Promise((resolve, reject) => {
                    const textArea = document.createElement('textarea');
                    textArea.value = hookURL;
                    document.body.appendChild(textArea);
                    textArea.select();
                    try {
                        document.execCommand('copy');
                        Swal.fire('Copied!', 'The URL has been copied to your clipboard.', 'success');
                    } catch (err) {
                        Swal.fire('Oops!', 'Something went wrong while copying the URL.', 'error');
                    } finally {
                        document.body.removeChild(textArea);
                    }
                });
            }
        });
    }


    const SyncModel = async () => {
        const token = cookies.token;// Fetch the token from cookies

        // Show loading indicator
        const swalLoading = Swal.fire({
            title: 'Syncing...',
            text: 'Please wait while we sync the models.',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false, // Prevent closing the alert
            showConfirmButton: false // Hide the confirm button
        });

        try {
            const response = await axios.post(`${Backend_Url}/helper/v1/sync-models`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const { message, results } = response.data;

            // Close the loading alert
            swalLoading.close();

            // Show success message
            Swal.fire({
                title: 'Sync Status',
                text: message,
                icon: 'success',
                confirmButtonText: 'OK'
            });

            // Show errors for specific models if any
            results.forEach(result => {
                if (result.status === 'error') {
                    Swal.fire({
                        title: 'Sync Error',
                        text: `Model: ${result.model}\nMessage: ${result.message}`,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            });

        } catch (error) {
            // Close the loading alert
            swalLoading.close();

            // Handle error from API call
            console.error('Error during sync:', error);
            Swal.fire({
                title: 'Sync Failed',
                text: `Error: ${error.response ? error.response.data.error : error.message}`,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };


    // ----------------------------modal open-----------------------------------
    const [modalopen, setmodalopen] = useState(false);
    const openCreateModal = () => {
        setmodalopen(true);
    }

    return (
        <div className="sidebar-WTP">
            <div className="Flex-logo-sidebar">
                <img className="Logo-Img-size" src={logo} alt='' />
                <h2>WaTechcherry</h2>
            </div>
            <nav style={{ maxHeight: "500px", overflowY: "scroll", scrollbarWidth: "none" }}>
                <ul>
                    <li>
                        <NavLink exact to="/dashboard" style={{ color: location.pathname == '/dashboard' ? '#007bff' : 'black', fontWeight: '600' }} activeClassName="active">
                            <Icon icon="bxs:dashboard" className="sidebar-WTP-icon" />
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/WTPCampaigns" activeClassName="active">
                            <Icon icon="simple-icons:campaignmonitor" className="sidebar-WTP-icon" />
                            Campaigns
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/WTPContacts" activeClassName="active">
                            <Icon icon="gravity-ui:persons-lock" className="sidebar-WTP-icon" /> Contacts
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/whatsappweb" activeClassName="active">

                            <Icon icon="solar:inbox-in-broken" className="sidebar-WTP-icon" />

                            <div className={`inbox-with-point`}>
                                Inbox
                                {toggle && <span><Icon icon="tabler:point-filled" style={{ color: "#54ff47" }} /></span>}
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/templates" activeClassName="active">
                            <Icon icon="eos-icons:templates-outlined" className="sidebar-WTP-icon" />Local Templates
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/metatemplates" activeClassName="active">
                            <Icon icon="tabler:template" className="sidebar-WTP-icon" /> Meta Templates
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/dummy-route"
                            activeClassName="active"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent navigation
                                handleURL();
                            }}
                        >
                            <Icon icon="carbon:url" className="sidebar-WTP-icon" /> Get URL
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/WhatsappAutomationAndChatBot"
                            activeClassName="active"
                        >
                            <Icon icon="material-symbols:automation" className="sidebar-WTP-icon" /> Automation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Whatsappapi" activeClassName="active">
                            <Icon icon="lets-icons:setting-alt-fill" className="sidebar-WTP-icon" /> API Setting
                        </NavLink>
                    </li>
                    {/* <li>

                        <NavLink to="/WhatsappWeb" activeClassName="active">
                            <Icon icon="eos-icons:templates-outlined" className="sidebar-WTP-icon" /> WhatsApp Web
                        </NavLink>
                    </li> */}
                    <li>
                        <NavLink to="/WTPBusinessAccount" activeClassName="active">
                            <Icon icon="fluent:person-accounts-24-regular" className="sidebar-WTP-icon" /> WABA Status
                        </NavLink>
                    </li>
                    <li>
                        <NavLink onClick={openCreateModal} style={{ color: '#333', fontWeight: '600' }} activeClassName="active">
                            <Icon icon="basil:instagram-solid" className="sidebar-WTP-icon" style={{ color: "black" }} />Instagram Post
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/dummy-route"
                            activeClassName="active"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent navigation
                                SyncModel();
                            }}
                        >
                            <Icon icon="ic:round-sync" className="sidebar-WTP-icon" /> Sync Database
                        </NavLink>
                    </li>
                </ul>
            </nav>
            {/* <div style={{ display: "flex", justifyContent: "center", padding: "60px" }}>
                <button onClick={handleURL} className="logout-button">Get URl</button>
            </div> */}
            {
                modalopen && <CreateCompaignModal closeWindow={setmodalopen} />
            }
        </div >
    )
}

export default WTPSideBar
