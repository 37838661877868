import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";
import WTPSideBar from './WTPSideBar';
import "../../css/wtpstyle.css";
import ModalQueryMaster from './ModalQueryMaster';
import axios from 'axios';
import Backend_Url from '../../config/config';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import MyContext from '../../context/Mycontext';



const CreateCampaigns = () => {

    /********************************************** */
    const { messagedeliverd, setmessagedeliverd } = useContext(MyContext) //----------------------------change 15/10/2024----------------------------
    const [headerContent, setHeaderContent] = useState([]);
    const [templateContent, setTemplateContent] = useState([]);
    const [activeTextarea, setActiveTextarea] = useState('header');// Track which textarea is active
    const [cookies] = useCookies(['token']);
    const [cookie] = useCookies(["token"]) //--------------------------------change 15/10/2024----------------------------------
    const token = cookie.token;
    // const [type, setType] = useState(1);
    const [apiData, setApiData] = useState([])
    const [apiData1, setApiData1] = useState([])
    const [selectedValue, setSelectedValue] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [mobileArr, setMobileArr] = useState([]);
    const [formData, setFormData] = useState({
        campaignId: '',
        campaignOwner: '',
        templateName: '',
        language: '',
        queryName: '',
        query: ''
    });
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [apiset, setApiSet] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateData, setTemplateData] = useState([]);
    // const [reportData, setReportData] = useState();

    console.log("Group Data", data);

    console.log("Selected Value:", selectedValue);
    console.log("Selected group:", selectedGroup);


    console.log("Entered MobileNo:", mobileNo);
    console.log("Selected Group MObile Numbers", mobileArr);
    console.log("Apisettin", apiset);



    const fetchTemplateData = async (type = 3) => {
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbytype/${type}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`,
                }
            });
            if (response.status === 200) {
                console.log("response", response.data.records);
                setTemplateData(response.data.records)
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchTemplateData();
    }, []);

    const fetchtempData = async (id) => {
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbyid/${id}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`,
                }
            });
            if (response.status === 200) {
                console.log("response", response.data);
                setFormData({
                    templateName: response.data.record.templateName,
                    language: response.data.record.language,
                });
                setHeaderContent(response.data.record.query)
                setTemplateContent(response.data.record.queryName)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchtempData(selectedTemplate)
        if (selectedTemplate === '') {
            setFormData({
                campaignId: '',
                campaignOwner: '',
                templateName: '',
                language: '',
                queryName: '',
                query: ''
            });
            setHeaderContent("")
            setTemplateContent("")
        }
        console.log("selectedTemplate", selectedTemplate);
    }, [selectedTemplate]);


    useEffect(() => {
        const fetchApiSetting = async () => {
            try {
                const response = await axios.get(`${Backend_Url}/whatsapp/v1/whatsapp-api`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response);
                    const defaultApi = response.data.find(item => item.IsDefault);
                    setApiSet(defaultApi)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchApiSetting();
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true); // Start loading
                const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setData(response.data); // Set data to state
            } catch (error) {
                console.error('Failed to fetch data:', error);
                // setError(error); // Set error to state
            } finally {
                // setLoading(false); // End loading
            }
        };

        fetchData();
    }, []);
    
    const handleMobngrpChange = (e) => {
        debugger
        const { name, value } = e.target;
        if (name === 'group') {
            setSelectedGroup(value);
        } else if (name === 'mobileNo') {
            setMobileNo(value);
        }
    };

    // const handleSend = async () => {
    //     // debugger
    //     const parseContent = (content) => {
    //         return content.split('\n').map(line => {
    //             if (!line.trim()) return null;


    //             const parts = line.split(',');


    //             const prefix = parts[0].trim();


    //             const text = parts.slice(1).join(',').trim();


    //             if (prefix.startsWith('@')) {
    //                 return { type: 'text', text: prefix.slice(1) };
    //             } else if (prefix.startsWith('image')) {
    //                 return {
    //                     type: 'image',
    //                     image: {
    //                         link: text,
    //                     },
    //                 };
    //             } else if (prefix.startsWith('document')) {
    //                 return {
    //                     type: 'document',
    //                     document: {
    //                         link: text,
    //                     },
    //                 };
    //             }


    //             // Log unknown prefixes
    //             console.warn('Unknown prefix:', prefix, 'Text:', text);
    //             return null;
    //         }).filter(param => param !== null);
    //     };


    //     const headerParams = headerContent > 0 ? parseContent(headerContent) : 0;
    //     const bodyParams = templateContent > 0 ? parseContent(templateContent) : 0;
    //     // const mobileNoArray = mobileNo.includes(',') ? mobileNo.split(',') : mobileNo;
    //     const mobileNoArray = typeof mobileNo === 'string'
    //         ? mobileNo.includes(',')
    //             ? mobileNo.split(',').map(num => num.trim()) // Split by comma and trim whitespace
    //             : [mobileNo.trim()] // Convert single mobileNo to array
    //         : mobileNo;

    //     // const sendPayload = {
    //     //     messaging_product: 'whatsapp',
    //     //     recipient_type: selectedGroup ? 'group' : 'individual',
    //     //     to: selectedGroup ? undefined : mobileNoArray,
    //     //     type: 'template',
    //     //     template: {
    //     //         name: formData.templateName,
    //     //         language: {
    //     //             code: formData.language,
    //     //         },
    //     //         ...(headerParams.length > 0 || bodyParams.length > 0 ? {
    //     //             components: [
    //     //                 ...(headerParams.length > 0 ? [{ type: 'header', parameters: headerParams }] : []),
    //     //                 ...(bodyParams.length > 0 ? [{ type: 'body', parameters: bodyParams }] : []),
    //     //             ],
    //     //         } : {}),
    //     //     },
    //     // };

    //     for (const number of mobileNoArray) {
    //         const sendPayload = {
    //             messaging_product: 'whatsapp',
    //             recipient_type: 'individual',
    //             to: number.trim(), // Trim any extra spaces
    //             type: 'template',
    //             template: {
    //                 name: formData.templateName,
    //                 language: {
    //                     code: formData.language,
    //                 },
    //                 ...(headerParams.length > 0 || bodyParams.length > 0 ? {
    //                     components: [
    //                         ...(headerParams.length > 0 ? [{ type: 'header', parameters: headerParams }] : []),
    //                         ...(bodyParams.length > 0 ? [{ type: 'body', parameters: bodyParams }] : []),
    //                     ],
    //                 } : {}),
    //             },
    //         };

    //         if (file) {
    //             const formData = new FormData();
    //             formData.append('file', file);
    //             formData.append('messaging_product', 'whatsapp');
    //             formData.append('type', 'image/jpeg');
    //             const formDataObject = {};
    //             formData.forEach((value, key) => {
    //                 formDataObject[key] = value;
    //             });
    //             console.log(formDataObject);
    //             try {
    //                 const response = await axios.post(apiset.MediaURL, formData, {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                         Authorization: apiset.data.RequestHeaderValueField1
    //                     },
    //                 });
    //                 const result = await response.json();
    //                 console.log('File upload response:', result);


    //                 const fileLink = result.id;

    //                 if (sendPayload.template.components.length > 0) {
    //                     if (sendPayload.template.components[0].parameters) {
    //                         sendPayload.template.components[0].parameters.push({
    //                             type: 'image',
    //                             image: {
    //                                 link: fileLink,
    //                             },
    //                         });
    //                     } else {
    //                         sendPayload.template.components[0].parameters = [{
    //                             type: 'image',
    //                             image: {
    //                                 link: fileLink,
    //                             },
    //                         }];
    //                     }
    //                 } else {
    //                     sendPayload.template.components.push({
    //                         type: 'header',
    //                         parameters: [{
    //                             type: 'image',
    //                             image: {
    //                                 link: fileLink,
    //                             },
    //                         }],
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error uploading file:', error);
    //             }

    //         }
    //         console.log(sendPayload)
    //         try {
    //             const Token = apiset.RequestHeaderValueField1;
    //             const headers = {
    //                 'Authorization': `${Token}`,
    //                 'Content-Type': 'application/json'
    //             };
    //             const response = await axios.post(apiset.URL, sendPayload, { headers })
    //             if (response) {
    //                 console.log(response)
    //                 alert("Message Sent Successfully")
    //             }
    //         } catch (error) {
    //             throw new Error("Unable To Send Message")
    //         }
    //     }

    // }


    // ----------------------change 15/10/2024-----------------------
    const handleSend = async () => {
        debugger
        const parseContent = (content) => {
            return content?.split('\n').map(line => {
                if (!line.trim()) return null;

                const parts = line.split(',');

                const prefix = parts[0].trim();
                const text = parts.slice(1).join(',').trim();

                if (prefix.startsWith('@')) {
                    return { type: 'text', text: prefix.slice(1) };
                } else if (prefix.startsWith('image')) {
                    return {
                        // type: 'image',
                        // image: {
                        //     link: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShL6maz7cEwIAAhHU74i7H6It79BodJyzWxw&s',
                        // },
                    };
                } else if (prefix.startsWith('document')) {
                    return {
                        // type: 'document',
                        // document: {
                        //     link: text,
                        // },
                    };
                } else if (prefix.startsWith('video' || 'Video')) {
                    return {
                        // type: 'document',
                        // document: {
                        //     link: text,
                        // },
                    };
                }

                // Log unknown prefixes
                console.warn('Unknown prefix:', prefix, 'Text:', text);
                return null;
            }).filter(param => param !== null);
        };

        const headerParams = headerContent != '' ? parseContent(headerContent) : [];
        const bodyParams = templateContent != '' ? parseContent(templateContent) : [];
        const mobileNoArray = typeof mobileNo === 'string'
            ? mobileNo.includes(',')
                ? mobileNo.split(',').map(num => num.trim())
                : [mobileNo.trim()]
            : mobileNo;


        const loadingSwal = Swal.fire({
            title: 'Sending messages...',
            text: 'Please wait while we send the messages.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const sendRequests = [];

        // // ---------------------------change 16/10/2024--------------------------
        // await axios.delete(`${Backend_Url}/messagesstatus/deleteByType/4`, {
        //     headers: {
        //         Authorization: `Bearer ` + cookie.token
        //     }
        // }).then((res) => {
        //     console.log(res.data);
        // }).catch((err) => {
        //     return
        // })


        // -------------------------change hexa-----------------------------------------------
        function generateUniqueHexCode(length) {
            let result = '';
            for (let i = 0; i < length; i++) {
                const randomHex = Math.floor(Math.random() * 16).toString(16); // Generate a random hex digit
                result += randomHex;
            }
            return result;
        }

        const uniqueHex = generateUniqueHexCode(8);
        console.log(uniqueHex);

        // ------------------------------------------------------------------------------------------------
        for (const number of mobileNoArray) {
            const sendPayload = {
                messaging_product: 'whatsapp',
                recipient_type: 'individual',
                to: number.trim(),
                type: 'template',
                template: {
                    name: formData.templateName,
                    language: {
                        code: formData.language,
                    },
                    ...(headerParams.length > 0 || bodyParams.length > 0 ? {
                        components: [
                            ...(headerParams.length > 0 ? [{ type: 'header', parameters: headerParams }] : []),
                            ...(bodyParams.length > 0 ? [{ type: 'body', parameters: bodyParams }] : []),
                        ],
                    } : {}),
                },
            };
            console.log("sendPayload", sendPayload);

            // Handle file upload
            if (file) {
                let fileData = new FormData();
                fileData.append('file', file);
                fileData.append('payload', JSON.stringify(sendPayload));
                fileData.append('hexacode', uniqueHex)
                fileData.append('compname', campaignId)
                console.log(apiset);

                const sendRequest = await axios.post(`${Backend_Url}/whatsapp/sendtemp/media`, fileData, {
                    headers: {
                        Authorization: `Bearer ${cookie.token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                },).then(response => {
                    console.log('sent response ', response);
                    // ---------------------------------change 15/10/2024-------------------------------------
                    debugger
                    // allresult = [...allresult, response.messages]
                    if (response.data.result.messages[0].message_status == 'accepted') {
                        setmessagedeliverd([...messagedeliverd, response.data.result])
                    }
                    return "Message Sent Successfully";
                }).catch(error => {
                    console.error('Error sending message:', error);
                    return "Unable To Send Message";
                });

                const msgsendresult = await sendRequest

                sendRequests.push(msgsendresult);
                console.log("sendRequest", sendRequest);
            } else {
                const sendRequest = await axios.post(`${Backend_Url}/whatsapp/sendtemp/text`, {
                    "payload": sendPayload,
                    'hexacode': uniqueHex,
                    'compname': campaignId
                }, {
                    headers: {
                        Authorization: `Bearer ${cookie.token}`
                    }
                },).then(response => {
                    console.log('sent response ', response);
                    debugger
                    // ---------------------------------change 15/10/2024-------------------------------------
                    if (response.data.result.messages[0].message_status == 'accepted') {
                        setmessagedeliverd([...messagedeliverd, response.data.result])
                    }
                    // allresult = [...allresult, response.messages]
                    return "Message Sent Successfully";
                }).catch(error => {
                    console.error('Error sending message:', error);
                    return "Unable To Send Message";
                });

                const msgsendresult = await sendRequest

                sendRequests.push(msgsendresult);
                console.log("sendRequest", sendRequest);
            }
        }

        try {
            const results = await Promise.all(sendRequests);
            console.log(results);

            const allMessagesSentSuccessfully = (results) => {
                return results.every(result => result === "Message Sent Successfully");
            };

            const getFailedMessages = (results) => {
                return results.filter(result => result.status === "Unable To Send Message");
            };

            if (allMessagesSentSuccessfully(results)) {
                Swal.fire({
                    title: 'Success',
                    text: "All messages sent successfully",
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                // Reset form data
                setFormData({
                    campaignId: '',
                    campaignOwner: '',
                    templateName: '',
                    language: '',
                    queryName: '',
                    query: ''
                })
                setFile(null)
                setMobileArr([])
                setCampaignId('')
                setSelectedTemplate('')
                setSelectedGroup('')
                setMobileNo('')
                setTemplateContent([])
                setHeaderContent([])
            }
            else {
                // Get failed messages
                const failedMessages = getFailedMessages(results);
                // Prepare error message
                const errorMessage = failedMessages.map(failed => `Contact ID ${failed.contactId} could not be sent.`).join('\n');

                Swal.fire({
                    title: 'Error',
                    text: `The following messages could not be sent:\n${errorMessage}`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error in sending messages:', error);
            //alert("Some messages were not sent successfully");
            Swal.fire({
                title: 'Error',
                text: "Some messages were not sent successfully",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }

    const handleClick = (text) => {
        // Append text to the active textarea with a comma separator
        const formattedText = `@${text}`;
        if (activeTextarea === 'header') {
            setHeaderContent(prevContent => prevContent ? `${prevContent},\n${formattedText}` : formattedText);
        } else {
            setTemplateContent(prevContent => prevContent ? `${prevContent},\n${formattedText}` : formattedText);
        }
    };

    const handleTextareaClick = (textareaName) => {
        setActiveTextarea(textareaName);
    }
    /*************************************************** */
    const [isModalOpenQuery, setModalOpenQuery] = useState(false);
    const showModalQuery = () => {
        setModalOpenQuery(true);
    };
    const hideModalQuery = () => {
        setModalOpenQuery(false);
    };


    // Handler for input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = async (e, type) => {
        e.preventDefault();
        // // debugger
        const token = cookies.token;

        if (!token) {
            console.error('No token found');
            return;
        }

        try {
            const response = await axios.post(`${Backend_Url}/whatsapp/v1/campaignorquery/save`, { ...formData, type }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                setFormData({
                    campaignId: '',
                    campaignOwner: '',
                    templateName: '',
                    language: '',
                    queryName: '',
                    query: ''
                })
                console.log(response);
                alert('Data saved successfully!');
                fetchData(2)
            }
        } catch (error) {
            console.error('Error saving data:', error);
            alert('Failed to save data.');
        }
    };

    const fetchData = async (type) => {
        const token = cookies.token;

        if (!token) {
            console.error('No token found');
            return;
        }

        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/campaignorquery/getbytype/${type}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                if (type === 1) {
                    console.log("Type 1 Data", response.data.records);
                    setApiData1(response.data.records)
                } else if (type === 2) {
                    setApiData(response.data.records)
                    console.log("Type 2 Data", response.data.records)
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(1);
        fetchData(2);
    }, []);


    const handleSelectChange = (e) => {
        setSelectedValue(e.target.value);
    };
    const handleCampaignSelectChange = (e) => {
        setCampaignId(e.target.value);
    };

    const [fieldName, setFieldName] = useState([])
    console.log(fieldName)

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            // const url = URL.createObjectURL(selectedFile);
            // setFileUrl(url); // Set the file URL
        }
    };


    const handlequery = async () => {
        // // debugger
        const query = selectedValue
        const token = cookies.token;

        if (!token) {
            console.error('No token found');
            return;
        }
        try {
            const response = await axios.post(`${Backend_Url}/whatsapp/v1/query/execute`, { query }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (response) {

                setFieldName(response.data)
                // alert("Executed")
                setSelectedValue("")
            }
        } catch (error) {
            throw new Error("Unable To Execute The Query")
        }
    }

    useEffect(() => {
        // // debugger
        const fetchData = async () => {
            if (!token) {
                console.error('No token found');
                return;
            }
            try {
                const response = await axios.get(`${Backend_Url}/contactgroups/${selectedGroup}/contacts`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response) {
                    console.log(response.data)
                    const waIds = response.data.contacts.map(contact => contact.wa_id);
                    setMobileArr(waIds)
                    setMobileNo(waIds)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData(selectedGroup);
    }, [selectedGroup]);

    return (
        <div className="Flex-Head-Right-Compaigns">
            <div>
                <WTPSideBar />
            </div>
            <div className="Right-Campaigns-Body">
                <div className="Campaigns_header">
                    <div className="Campaigns_header">
                        <h3>Campaigns | Create New Campaigns</h3>
                    </div>
                    <div className="Campaigns_headerRight">
                        <button className="Campaigns_headerButton" >
                            <Icon icon="pepicons-pop:hash-circle-filled" />
                        </button>
                    </div>
                </div>
                <div className="Campaigns_body_Bg">
                    <div className="Content-padding-Campaigns">
                        <div className="Flex-Input-Campaigns">
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Campaign Id</label>
                                <input className="" type="text" name="campaignId" placeholder="Campaign Id" onChange={handleChange} value={formData.campaignId} />
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl"> Campaign owner name</label>
                                <input className="" type="text" name="campaignOwner" placeholder="Campaign owner name" onChange={handleChange} value={formData.campaignOwner} />
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <button className="view-all-button" onClick={(e) => handleSubmit(e, 1)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="Content-padding-Campaigns">
                        <div className="Flex-Input-Campaigns">


                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl"> Campaign Id</label>
                                <div className='Icon-flex-add'>
                                    <select className="Query-select-Box" type="text" name="" placeholder="" value={campaignId} onChange={handleCampaignSelectChange}>
                                        <option>--select--</option>
                                        <option selected value=''>Blank</option>
                                        {apiData1 && apiData1.map((item, index) => (
                                            <option key={index} value={item.campaignId}>
                                                {item.campaignId}
                                            </option>
                                        ))}


                                    </select>
                                </div>
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Select Template</label>
                                <select
                                    className="Query-select-Box"
                                    name="dropdown"
                                    value={selectedTemplate}
                                    onChange={(e) => setSelectedTemplate(e.target.value)}
                                >
                                    <option value="">Select a template</option>
                                    {templateData && templateData.map((template, index) => (
                                        <option key={index} value={template.id}>
                                            {template.templateName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Template Name</label>
                                {/* <input className="" type="text" name="templateName" placeholder="Template name as meta" onChange={handleChange} value={formData.templateName} readOnly /> */}
                                <div className="form-display">{formData.templateName || 'N/A'}</div>
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Language</label>
                                {/* <input className="" type="text" name="language" placeholder="Language(ex:en_US,en_UK)" onChange={handleChange} value={formData.language} readOnly /> */}
                                <div className="form-display">{formData.language || 'N/A'}</div>
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl"> Query Name</label>
                                <div className='Icon-flex-add'>
                                    <select className="Query-select-Box" type="text" name="" placeholder="" value={selectedValue} onChange={handleSelectChange}>
                                        <option>--select--</option>
                                        <option selected value=''>Blank</option>
                                        {apiData && apiData.map((item, index) => (
                                            <option key={index} value={item.query}>
                                                {item.queryName}
                                            </option>
                                        ))}


                                    </select>
                                    <div className="icon-position-camp" onClick={showModalQuery}>
                                        <Icon icon="ant-design:plus-circle-filled" />
                                    </div>
                                    <div className="icon-position-camp" onClick={handlequery}>
                                        <Icon icon="fluent-mdl2:rerun" width="24" height="24" style={{ color: "black" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isModalOpenQuery && (
                        <div className="modal-Category">
                            <div className="modal-content modal-campaigns-width">
                                <div className="modal-title">
                                    <h3 className="modal-heading-top">Query Master</h3>
                                    <span className="close" onClick={hideModalQuery}>&times;</span>
                                </div>
                                <div>
                                    < ModalQueryMaster
                                        handleChange={handleChange}
                                        formData={formData}
                                        setFormData={setFormData}
                                        onClick={(e) => handleSubmit(e, 2)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='Content-padding-Campaigns'>
                        <div className='Flex-Area-Campaigns'>
                            <div className='Header-Area-width'>
                                <div>
                                    <textarea className="Input-textarea-Box" type="text" name='header' value={headerContent} onChange={(e) => setHeaderContent(e.target.value)} placeholder='Header variable' onClick={() => handleTextareaClick('header')} />
                                </div>
                                <div>
                                    <textarea className="Input-textarea-Box" type="text" name='template' value={templateContent} onChange={(e) => setTemplateContent(e.target.value)} placeholder='body variable' onClick={() => handleTextareaClick('template')} />
                                </div>
                            </div>
                            <div className='Right-Area-Box'>

                                {fieldName && fieldName.columnNames && fieldName.columnNames.length > 0 ? (
                                    fieldName.columnNames.map((item, index) => (
                                        <div key={index} className='Right-Content-cam-text' onClick={() => handleClick(item)}>
                                            {item}
                                        </div>
                                    ))
                                ) : (
                                    <div className='No-columns-available'>No columns available</div>
                                )}
                                {/* <div className='Right-Content-cam-text' onClick={() => handleClick('FirstName')}>First Name</div>
                                <div className='Right-Content-cam-text' onClick={() => handleClick('LastName')}>Last Name</div>
                                <div className='Right-Content-cam-text' onClick={() => handleClick('MobileNo')}>Mobile No</div>
                                <div className='Right-Content-cam-text' onClick={() => handleClick('Address')}>Address</div>
                                <div className='Right-Content-cam-text' onClick={() => handleClick('City')}>City</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="Content-padding-Campaigns">
                        <div className="Flex-Input-Campaigns">
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Select Group</label>
                                <div className='Icon-flex-add'>
                                    <select
                                        className="Query-select-Box"
                                        name="group"
                                        onChange={handleMobngrpChange}
                                        value={selectedGroup}
                                    >
                                        <option value="">--select--</option>
                                        {data.map((group) => (
                                            <option key={group.wa_id} value={group.wa_id}>
                                                {group.Name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <h3>OR</h3>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Mobile No</label>
                                <textarea className="Mobile-textarea-input" type="text" name="mobileNo" placeholder="Mobile No" onChange={handleMobngrpChange} value={mobileNo} />
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Upload File</label>
                                <input
                                    type="file"
                                    accept="image/*,application/pdf" // Adjust accepted file types as needed
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="view-all-container">
                        <button className="view-all-button" onClick={handleSend}>
                            Send
                        </button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default CreateCampaigns
