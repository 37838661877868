import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import Backend_Url from '../../config/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

const ModalImportContact = ({ fetchContactData, setisModalImportContact, fetchGrpData, setintervelforprogree }) => {

    const [groupId, setGroupId] = useState('');
    const [data, setData] = useState();
    const [cookie] = useCookies(["token"])
    const token = cookie.token;
    const [file, setFile] = useState(null);

    console.log("Group Data", data);
    console.log("Group Id", groupId);
    console.log("File", file);

    const downloadSampleFormat = () => {
        const sampleFormatUrl = `${Backend_Url}/download/sample-format`; // URL to your Node.js endpoint
        axios({
            url: sampleFormatUrl,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            // Create a new Blob object using the response data
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'sample-format.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.error('Error downloading file:', error);
        });
    };

    const fetchData = async () => {
        try {

            const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [modifiedFile, setModifiedFile] = useState(null);

    // const handleFileChange = async (event) => {
    //     debugger
    //     const selectedFile = event.target.files[0];
    //     setFile(selectedFile);

    //     if (!selectedFile) return;

    //     setIsLoading(true);
    //     Swal.fire({
    //         title: 'Processing...',
    //         text: 'Please wait while we process your file.',
    //         didOpen: () => {
    //             Swal.showLoading();
    //         }
    //     });

    //     try {
    //         const data = await selectedFile.arrayBuffer();
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const sheetName = workbook.SheetNames[0];
    //         const sheet = workbook.Sheets[sheetName];
    //         const jsonData = XLSX.utils.sheet_to_json(sheet);

    //         // Process phone numbers
    //         const processedData = jsonData.map(row => {
    //             if (row['Phone No'] && !row['Phone No'].toString().startsWith('91')) {
    //                 row['Phone No'] = '91' + row['Phone No'];
    //             }
    //             return row;
    //         });

    //         // Create a new workbook and add the processed data
    //         const newWorkbook = XLSX.utils.book_new();
    //         const newWorksheet = XLSX.utils.json_to_sheet(processedData);
    //         XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, sheetName);

    //         // Generate the new file
    //         const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
    //         const newFile = new File([wbout], `modified_${selectedFile.name}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //         setModifiedFile(newFile);
    //         if (newFile) {
    //             setFile('')
    //         }

    //         Swal.fire('Success', 'File processed and modified file is ready!', 'success');
    //     } catch (error) {
    //         Swal.fire('Error', 'There was a problem processing your file.', 'error');
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleFileChange = async (event) => {
        debugger
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        if (!selectedFile) return;

        setIsLoading(true);
        Swal.fire({
            title: 'Processing...',
            text: 'Please wait while we process your file.',
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const data = await selectedFile.arrayBuffer();
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON, keeping all columns including empty ones
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Use header: 1 to preserve all rows and columns

            // Identify header row (first row of jsonData)
            const header = jsonData[0];
            const rows = jsonData.slice(1);

            // Find index of 'Phone No' column
            const phoneNoIndex = header.indexOf('Phone No');
            let needsModification = false;

            const processedRows = rows.map(row => {
                if (phoneNoIndex !== -1 && row[phoneNoIndex] && !row[phoneNoIndex].toString().startsWith('91')) {
                    needsModification = true;
                    row[phoneNoIndex] = '91' + row[phoneNoIndex];
                }
                return row;
            });

            if (needsModification) {
                // Combine header with processed rows
                const processedData = [header, ...processedRows];

                // Create a new workbook and add the processed data
                const newWorkbook = XLSX.utils.book_new();
                const newWorksheet = XLSX.utils.aoa_to_sheet(processedData); // Use aoa_to_sheet to keep all columns
                XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, sheetName);

                const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
                const newFile = new File([wbout], `modified_${selectedFile.name}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                setModifiedFile(newFile);

                Swal.fire({
                    title: 'Success',
                    text: 'File processed and modified file is ready!',
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    // willClose: () => {
                    //     
                    //     
                    // }
                });
            } else {
                setModifiedFile(selectedFile);
                Swal.fire({
                    title: 'No Modification Needed',
                    text: 'The file is good to go!',
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    // willClose: () => {
                    //     // Callback when the alert is closed
                    //     // You can add additional code here if needed
                    // }
                });
            }
        } catch (error) {
            Swal.fire('Error', 'There was a problem processing your file.', 'error');
        } finally {
            setIsLoading(false);
        }
    };


    const handleDownloadModifiedFile = () => {
        if (!modifiedFile) return;
        const url = URL.createObjectURL(modifiedFile);
        const link = document.createElement('a');
        link.href = url;
        link.download = modifiedFile.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the URL object
    };

    // --------------------------------get progress value --------------------------------

    const [loader, setloader] = useState(false);

    const handleFileUpload = async () => {
        debugger    
        setloader(true)
        if (!file) {
            Swal.fire({
                icon: 'warning',
                title: 'No File Selected',
                text: 'Please select a file to upload.',
            });
            return;
        }

        const formData = new FormData();
        formData.append('groupId', groupId);
        formData.append('file', modifiedFile);

        try {
            setloader(true)
            // if (!loader) {
            //     Swal.fire({
            //         title: 'Uploading...',
            //         showConfirmButton: false,
            //         allowOutsideClick: true, // Prevent closing on outside click
            //         didOpen: () => {
            //             Swal.showLoading(); // Show the loading spinner
            //         }
            //     });
            // }
            const response = await axios.post(`${Backend_Url}/contacts/upload-contacts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}` // Ensure you have the token available
                }
            });
            if (response.status === 200) {
                debugger
                setintervelforprogree()
                console.log(response);

                setloader(false)
                setisModalImportContact()
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Imported Successfully',
                //     text: 'The contacts has been imported successfully.',
                // });
                fetchContactData()
                fetchGrpData()
            }
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data && error.response.data.error
                ? error.response.data.error === 'Group ID is required'
                    ? 'There was an error uploading the file.</br> <strong><em>Please select a group</em></strong>'
                    : error.response.data.error
                : 'There was an error uploading the file. Please try again later.';

            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                html: errorMessage,
            });
        }
    };

    return (
        <div>
            <div className="Model-Query-Content">
                <div className="Flex-Input-Campaigns">
                    <div className='Input-Box-Compaigns Query-width-name'>
                        {/* <input className="" type="text" name="" placeholder="Group Name"
                            onChange={(e) => setGroupName(e.target.value)}
                            value={groupName} /> */}
                        <select name="group" id="group" onChange={(e) => setGroupId(e.target.value)} value={groupId}>
                            <option value="">Select Group</option>
                            {data && data.map((item) => (
                                <option key={item.wa_id} value={item.wa_id}>{item.Name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <input type="file" name="" placeholder="" onChange={handleFileChange} />
                    </div>
                </div>
                <button onClick={handleFileUpload}>Upload Contacts</button>
                <button onClick={handleDownloadModifiedFile}>Download modified file</button>
                <button onClick={downloadSampleFormat}>Download Sample Format</button>
            </div>
        </div >
    )
}

export default ModalImportContact
