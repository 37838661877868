import axios from "axios";
import React, { useMemo, useRef, useState, useEffect } from "react";
import Backend_Url from "../../config/config";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

const ModelAddGroups = ({ editableItem, fetchGrpData }) => {
    const [groupName, setGroupName] = useState('');
    const [cookie] = useCookies(["token"])
    const token = cookie.token;
    const [data, setData] = useState([]);
    console.log("Group name", groupName);
    console.log("Data", data);

    const [activeTab, setActiveTab] = useState(() => {
        const savedTab = localStorage.getItem('activeTab');
        return savedTab !== null ? JSON.parse(savedTab) : 0;
    });

    const handleClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    useEffect(() => {
        // Save the state to local storage whenever it changes
        localStorage.setItem('activeTab', JSON.stringify(activeTab));
    }, [activeTab]);


    useEffect(() => {
        // debugger
        if (editableItem != undefined) {
            setGroupName(editableItem.Name)
        }
    }, [editableItem]);

    const handleSave = async () => {

        if (!token) {
            alert("Token Not Found");
            return;
        }
        try {
            const response = await axios.post(`${Backend_Url}/contactgroups/createGroup`, { groupName }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response && response.status === 201) {
                fetchData()
                fetchGrpData()
                setGroupName('')
                Swal.fire('Created Successfully', '', 'success');
            }
        } catch (error) {
            console.error("Unable To Create A Group", error);
            Swal.fire('Error', 'Unable to create a group', 'error');
        }
    };


    const fetchData = async () => {
        try {
            // setLoading(true); // Start loading
            const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data); // Set data to state
        } catch (error) {
            console.error('Failed to fetch data:', error);

        }
    };

    useEffect(() => {
        fetchData();
        if (editableItem) {
            setActiveTab(0)
        }
    }, []);

    return (

        <div>
            <div className="Model-Query-Content">
                <div className="Contacts-Groups-content">
                    <div
                        className={`Groups-Contacts-width ${activeTab === 0 ? 'active' : ''}`}
                        onClick={() => handleClick(0)}
                    >
                        {editableItem ? "Edit Group" : "New Group"}
                    </div>
                    <div
                        className={`Groups-Contacts-width ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => handleClick(1)}
                    >
                        Group List
                    </div>
                </div>
                <div className="Content-padding-Campaigns">
                    {activeTab === 0 && (
                        <div className="Flex-Input-Campaigns">
                            <div className='Input-Box-Compaigns Query-width-name'>
                                <input className="" type="text" name="" placeholder="Group Name"
                                    onChange={(e) => setGroupName(e.target.value)}
                                    value={groupName} />
                            </div>
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div className="Flex-Input-Campaigns">
                            {/* <div className='Input-Box-Compaigns Query-width-name'>
                            <label className="Campaigns-lbl">Select Group</label>
                                <select className="ExistingGroup-select-Box" type="text" name="" placeholder="Select Group">
                                    <option>--Select--</option>
                                </select>
                            </div> */}
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={item.wa_id}>
                                            <td>{index + 1}</td>
                                            <td>{item.Name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {activeTab === 0 && (
                        <div className="view-all-container">
                            <button className="view-all-button">
                                Cancel
                            </button>
                            <button className="view-all-button" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    )}
                    {/* <div className="view-all-container">
                        <button className="view-all-button">
                            Cancel
                        </button>
                        <button className="view-all-button" onClick={handleSave}>
                            Save
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ModelAddGroups;
