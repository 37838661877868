import React, { useState, useEffect } from 'react';
import WTPSideBar from './WTPSideBar';
import { Icon } from '@iconify/react';
import Backend_Url from '../../config/config';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';


const MetaTemplates = () => {
    // State to store an array of template data
    const [templates, setTemplates] = useState([]);
    const [cookies] = useCookies(['token']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formData, setFormData] = useState({});
    const [mobileNo, setMobileNo] = useState('');
    const [data, setData] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate()


    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true); // Start loading
                const response = await axios.get(`${Backend_Url}/contactgroups/getall`, {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`
                    }
                });
                setData(response.data); // Set data to state
            } catch (error) {
                console.error('Failed to fetch data:', error);
                // setError(error); // Set error to state
            } finally {
                // setLoading(false); // End loading
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        debugger
        const fetchData = async () => {
            if (!cookies.token) {
                console.error('No token found');
                return;
            }
            try {
                const response = await axios.get(`${Backend_Url}/contactgroups/${selectedGroup}/contacts`, {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`
                    }
                });
                if (response) {
                    console.log(response.data)
                    const waIds = response.data.contacts.map(contact => contact.wa_id);
                    // setMobileArr(waIds)
                    setMobileNo(waIds)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData(selectedGroup);
    }, [selectedGroup]);


    useEffect(() => {
        if (selectedGroup === '') {
            setMobileNo('')
        }
    }, [selectedGroup]);


    const handleMobngrpChange = (e) => {
        debugger
        const { name, value } = e.target;
        if (name === 'group') {
            setSelectedGroup(value);
        } else if (name === 'mobileNo') {
            setMobileNo(value);
        }
    };
    // useEffect(() => {
    //     const apiData = [
    //         {
    //             id: '1',
    //             name: 'Ready',
    //             language: 'en_US',
    //             status: 'APPROVED',
    //         },
    //         {
    //             id: '2',
    //             name: 'Pending',
    //             language: 'es_ES',
    //             status: 'PENDING',
    //         },
    //         {
    //             id: '3',
    //             name: 'Completed',
    //             language: 'fr_FR',
    //             status: 'COMPLETED',
    //         },
    //     ];

    //     setTemplates(apiData); // Simulate setting the fetched templates
    // }, []);

    // const handleTemplateDelete = async () => {
    //     try {
    //         // Send DELETE request to the API
    //         const response = await axios.delete(`${Backend_Url}/whatsapp/deletetemplate?templateName=${selectedTemplate.name}&templateId=${selectedTemplate.id}`, {
    //             headers: {
    //                 Authorization: `Bearer ${cookies.token}`
    //             }
    //         });


    //         if (response.data.success) {

    //             Swal.fire({
    //                 title: 'Template Deleted!',
    //                 text: 'The template has been successfully deleted.',
    //                 icon: 'success',
    //                 confirmButtonText: 'OK'
    //             }).then(() => {

    //                 setIsModalOpen(false);

    //                 fetchData();
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error deleting template:", error);

    //         Swal.fire({
    //             title: 'Error!',
    //             text: 'Failed to delete the template. Please try again.',
    //             icon: 'error',
    //             confirmButtonText: 'OK'
    //         });
    //     }
    // };

    const handleTemplateDelete = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this template? This action cannot be undone.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${Backend_Url}/whatsapp/deletetemplate?templateName=${selectedTemplate.name}&templateId=${selectedTemplate.id}`, {
                        headers: {
                            Authorization: `Bearer ${cookies.token}`
                        }
                    });

                    if (response.data.success) {
                        Swal.fire({
                            title: 'Template Deleted!',
                            text: 'The template has been successfully deleted.',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        }).then(() => {
                            setIsModalOpen(false);
                            fetchData();
                        });
                    }
                } catch (error) {
                    console.error("Error deleting template:", error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to delete the template. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            }
        });
    };



    const handleSendButtonClick = async () => {
        debugger
        const payloads = createPayload();
        const loadingSwal = Swal.fire({
            title: 'Sending messages...',
            text: 'Please wait while we send the messages.',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const sendRequests = [];

        for (const payload of payloads) {
            let file = formData.file;
            const id = file ? "media" : "text";

            const url = `${Backend_Url}/whatsapp/sendtemp/${id}`;

            if (file) {

                const fileData = new FormData();
                fileData.append("file", file);
                fileData.append("payload", JSON.stringify(payload));

                try {

                    const mediaResponse = await axios.post(url, fileData, {
                        headers: {
                            Authorization: `Bearer ${cookies.token}`,
                            "Content-Type": "multipart/form-data"
                        }
                    });

                    console.log("Media send response:", mediaResponse.data);
                    sendRequests.push("Message Sent Successfully");

                } catch (error) {
                    console.error("Error sending media message:", error);
                    sendRequests.push("Unable To Send Media Message");
                }
            } else {
                // Prepare the payload for sending text
                try {
                    const sendResponse = await axios.post(url, {
                        payload: payload
                    }, {
                        headers: {
                            Authorization: `Bearer ${cookies.token}`,
                            "Content-Type": "application/json"
                        }
                    });

                    console.log("Text send response:", sendResponse.data);
                    sendRequests.push("Message Sent Successfully");

                } catch (error) {
                    console.error("Error sending text message:", error);
                    sendRequests.push("Unable To Send Text Message");
                }
            }
        }

        try {
            const results = await Promise.all(sendRequests);
            console.log(results);

            const allMessagesSentSuccessfully = (results) => {
                return results.every(result => result === "Message Sent Successfully");
            };

            const getFailedMessages = (results) => {
                return results.filter(result => result.status === "Unable To Send Message");
            };

            if (allMessagesSentSuccessfully(results)) {
                Swal.fire({
                    title: 'Success',
                    text: "All messages sent successfully",
                    icon: 'success',
                    confirmButtonText: 'OK'
                });



                setFormData({})
                setSelectedGroup('')
                setMobileNo('')
                setVideoPreview('')
                setImagePreview('');


            }
            else {
                const failedMessages = getFailedMessages(results);
                const errorMessage = failedMessages.map(failed => `Contact ID ${failed.contactId} could not be sent.`).join('\n');
                Swal.fire({
                    title: 'Error',
                    text: `The following messages could not be sent:\n${errorMessage}`,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } catch (error) {
            console.error('Error in sending messages:', error);
            //alert("Some messages were not sent successfully");
            Swal.fire({
                title: 'Error',
                text: "Some messages were not sent successfully",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }

        console.log("Send Requests Results:", sendRequests);
    };

    // Payload creation logic
    // const createPayload = () => {
    //     debugger
    //     return mobileNo.map((number) => {
    //         // Create arrays to hold body and header parameters
    //         const bodyParameters = [];
    //         const headerParameters = [];

    //         selectedTemplate.components.forEach(component => {
    //             if (component.type === 'BODY') {

    //                 const matches = Array.from(component.text.matchAll(/{{(\d+)}}/g));

    //                 matches.forEach((match) => {
    //                     const variableIndex = match[1];
    //                     const variableValue = formData[`variable_${variableIndex}`] || "";

    //                     bodyParameters.push({
    //                         type: "text",
    //                         text: variableValue
    //                     });
    //                 });
    //             }

    //             if (component.type === 'HEADER') {
    //                 if (component.format === 'TEXT') {

    //                     const matches = Array.from(component.text.matchAll(/{{(\d+)}}/g));
    //                     if (matches.length > 0) {

    //                         let modifiedHeaderText = component.text;

    //                         matches.forEach((match) => {
    //                             const variableIndex = match[1];
    //                             const variableValue = formData[`variable_${variableIndex}`] || "";
    //                             modifiedHeaderText = modifiedHeaderText.replace(match[0], variableValue);
    //                         });

    //                         headerParameters.push({
    //                             type: "text",
    //                             text: modifiedHeaderText
    //                         });
    //                     }
    //                 } else if (component.format === 'IMAGE') {

    //                     headerParameters.push({
    //                         // type: "image"
    //                     });
    //                 } else if (component.format === 'VIDEO') {

    //                     headerParameters.push({

    //                     });
    //                 }
    //             }
    //         });

    //         return {
    //             messaging_product: "whatsapp",
    //             recipient_type: "individual",
    //             to: number.trim(),
    //             type: "template",
    //             template: {
    //                 name: selectedTemplate.name,
    //                 language: {
    //                     code: selectedTemplate.language,
    //                 },
    //                 ...(headerParameters.length > 0 || bodyParameters.length > 0 ? {
    //                     components: [
    //                         ...(headerParameters.length > 0 ? [{ type: 'header', parameters: headerParameters }] : []),
    //                         ...(bodyParameters.length > 0 ? [{ type: 'body', parameters: bodyParameters }] : []),
    //                     ],
    //                 } : {}),
    //             },
    //         };
    //     });
    // };


    const createPayload = () => {
        debugger;

        // Ensure mobileNo is an array
        const mobileNumbers = Array.isArray(mobileNo) ? mobileNo : [mobileNo];

        return mobileNumbers.map((number) => {
            // Create arrays to hold body and header parameters
            const bodyParameters = [];
            const headerParameters = [];

            selectedTemplate.components.forEach(component => {
                if (component.type === 'BODY') {
                    const matches = Array.from(component.text.matchAll(/{{(\d+)}}/g));

                    matches.forEach((match) => {
                        const variableIndex = match[1];
                        const variableValue = formData[`variable_${variableIndex}`] || "";

                        bodyParameters.push({
                            type: "text",
                            text: variableValue
                        });
                    });
                }

                if (component.type === 'HEADER') {
                    if (component.format === 'TEXT') {
                        const matches = Array.from(component.text.matchAll(/{{(\d+)}}/g));
                        if (matches.length > 0) {
                            let modifiedHeaderText = component.text;

                            matches.forEach((match) => {
                                const variableIndex = match[1];
                                const variableValue = formData[`variable_${variableIndex}`] || "";
                                modifiedHeaderText = modifiedHeaderText.replace(match[0], variableValue);
                            });

                            headerParameters.push({
                                type: "text",
                                text: modifiedHeaderText
                            });
                        }
                    } else if (component.format === 'IMAGE') {
                        // Handle image format here if needed
                        headerParameters.push({
                            // Add relevant parameters for image
                        });
                    } else if (component.format === 'VIDEO') {
                        // Handle video format here if needed
                        headerParameters.push({
                            // Add relevant parameters for video
                        });
                    }
                }
            });

            return {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: number.trim(),
                type: "template",
                template: {
                    name: selectedTemplate.name,
                    language: {
                        code: selectedTemplate.language,
                    },
                    ...(headerParameters.length > 0 || bodyParameters.length > 0 ? {
                        components: [
                            ...(headerParameters.length > 0 ? [{ type: 'header', parameters: headerParameters }] : []),
                            ...(bodyParameters.length > 0 ? [{ type: 'body', parameters: bodyParameters }] : []),
                        ],
                    } : {}),
                },
            };
        });
    };

    // Call handleSendButtonClick when the Send button is clicked





    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
        setIsModalOpen(true);
    };

    useEffect(() => {
        console.log("selectedTemplate", selectedTemplate);
    }, [selectedTemplate]);

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setFormData({})
    };

    const fetchData = async () => {
        debugger
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/templates`,
                {
                    headers: {
                        Authorization: `Bearer ${cookies.token}`,
                    }
                }
            );
            console.log("response", response);

            if (response) {
                setTemplates(response.data.data)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        console.log("templates", templates);
    }, [templates]);


    const handleCreate = () => {
        navigate("/createcustomtemplate");
    }

    return (
        <div className='Flex-Head-Right-Compaigns'>
            <div>      {/* Side Bar */}
                <WTPSideBar />
            </div>

            <div className="templates-container-meta-template">




                <div className="big-card-container-meta-template">
                    <div className="big-card-header-meta-template">
                        <div style={{ display: "flex", justifyContent: "center", }}>
                            <h2>Templates</h2>
                        </div>
                        <button style={{ display: "flex", alignItems: "center", position: "absolute", right: "18px", top: "0px" }} onClick={() => handleCreate()}>Create <Icon icon="ph:plus-fill" style={{ color: "black" }} /></button>
                    </div>

                    <div className="big-card-body-meta-template" >
                        {/* {templates ? (templates.map((template) => (
                            <div key={template.id} className="small-card-meta-template" onClick={() => handleTemplateClick(template)}>
                                <div className="small-card-header-meta-template">
                                    <h3>{template.name}</h3>
                                </div>
                                <div className="small-card-body-meta-template">
                                    <p><strong>Language:</strong> {template.language}</p>
                                    <p><strong>Status:</strong> {template.status}</p>
                                </div>
                                <button
                                    className="arrow-button-meta-template"
                                    onClick={() => handleTemplateClick(template)}
                                >
                                    <Icon icon="eva:arrow-ios-forward-outline" style={{ color: "black" }} width="24" height="24" />

                                </button>
                            </div>
                        ))) :
                            (<div></div>)
                        } */}

                        {loading ? (
                            <div className="loading-spinner-meta-template"></div>
                        ) : templates.length > 0 ? (
                            templates.map((template) => (
                                <div key={template.id} className="small-card-meta-template" onClick={() => handleTemplateClick(template)}>
                                    <div className="small-card-header-meta-template">
                                        <h3>{template.name}</h3>
                                    </div>
                                    <div className="small-card-body-meta-template">
                                        <p><strong>Language:</strong> {template.language}</p>
                                        <p><strong>Status:</strong> {template.status}</p>
                                    </div>
                                    <button
                                        className="arrow-button-meta-template"
                                        onClick={() => handleTemplateClick(template)}
                                    >
                                        <Icon icon="eva:arrow-ios-forward-outline" style={{ color: "black" }} width="24" height="24" />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div>No templates available.</div>
                        )}

                    </div>
                </div>
            </div>



            {isModalOpen && (
                <div className="modal-overlay-meta-template">
                    <div className="modal-content-meta-template">
                        <span className="close-button-meta-template" onClick={closeModal}>&times;</span>
                        <h2>Template Details</h2>
                        <div className="template-details-meta-template">
                            <p><strong>Template Name:</strong> {selectedTemplate.name}</p>
                            <p><strong>Language:</strong> {selectedTemplate.language}</p>
                            <p><strong>Status:</strong> {selectedTemplate.status}</p>
                            <p><strong>Category:</strong> {selectedTemplate.category}</p>
                            <h3>Components:</h3>
                            {selectedTemplate.components.map((component, index) => (
                                <div key={index} className="component-details-meta-template ">
                                    <p><strong>Type:</strong> {component.type}</p>
                                    {component.text && <p><strong>Text:</strong> {component.text}</p>}
                                    {/* Check for variables in the BODY component and generate input fields */}
                                    {component.type === 'BODY' && component.text.includes('{{') && (
                                        <div>
                                            {Array.from(component.text.matchAll(/{{\d+}}/g)).map((match, idx) => (
                                                <div key={idx} className='Flex-Input-Campaigns' style={{ marginBottom: '12px' }}>
                                                    <label className="Campaigns-lbl">Enter value for variable {match[0]}:</label>
                                                    <input

                                                        type="text"
                                                        value={formData[`variable_${idx + 1}`] || ''}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            [`variable_${idx + 1}`]: e.target.value
                                                        })}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {component.type === 'HEADER' && component.format === 'IMAGE' && (
                                        <div className="upload-container-meta-template"
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                const file = e.dataTransfer.files[0];
                                                if (file && file.type.startsWith('image/')) {
                                                    setFormData({
                                                        ...formData,
                                                        file: file,
                                                    });
                                                    setImagePreview(URL.createObjectURL(file));
                                                }
                                            }}
                                            onDragLeave={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                        >
                                            <label className="upload-label-meta-template">Upload Image:</label>
                                            {!imagePreview ? (
                                                <div className="upload-area-meta-template">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setFormData({
                                                                ...formData,
                                                                file: file,
                                                            });
                                                            setImagePreview(file ? URL.createObjectURL(file) : null);
                                                        }}
                                                        id={`file-upload-${index}`} // Unique ID for each upload area
                                                        className="upload-input-meta-template"
                                                    />
                                                    <label htmlFor={`file-upload-${index}`} className="upload-box-meta-template">
                                                        <span className="upload-icon-meta-template">
                                                            <Icon icon="mdi:file-upload-outline" width="40" height="40"></Icon>
                                                        </span>
                                                        <p className="upload-text-meta-template">Drag & drop your image here, or click to upload</p>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div className="image-preview-container-meta-template">
                                                    <img src={imagePreview} alt="Preview" className="image-preview-meta-template" />
                                                    <button
                                                        className="remove-image-button-meta-template"
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                file: null,
                                                            });
                                                            setImagePreview(null);
                                                        }}
                                                    >
                                                        <span className={``}>
                                                            <Icon icon="fluent:delete-12-filled" style={{ color: "#ffffff" }} />
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* Handle HEADER component with VIDEO */}
                                    {component.type === 'HEADER' && component.format === 'VIDEO' && (
                                        <div className="upload-container-meta-template"
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                const file = e.dataTransfer.files[0];
                                                if (file && file.type.startsWith('video/')) {
                                                    setFormData({
                                                        ...formData,
                                                        file: file,
                                                    });
                                                    setVideoPreview(URL.createObjectURL(file));
                                                }
                                            }}
                                        >
                                            <label className="upload-label-meta-template">Upload Video:</label>
                                            {!videoPreview ? (
                                                <div className="upload-area-meta-template">
                                                    <input
                                                        type="file"
                                                        accept="video/*"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setFormData({
                                                                ...formData,
                                                                file: file,
                                                            });
                                                            setVideoPreview(file ? URL.createObjectURL(file) : null);
                                                        }}
                                                        id={`video-upload-${index}`}
                                                        className="upload-input-meta-template"
                                                    />
                                                    <label htmlFor={`video-upload-${index}`} className="upload-box-meta-template">
                                                        <span className="upload-icon-meta-template">
                                                            <Icon icon="mdi:file-upload-outline" width="40" height="40" />
                                                        </span>
                                                        <p className="upload-text-meta-template">Drag & drop your video here, or click to upload</p>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div className="image-preview-container-meta-template">
                                                    <video controls src={videoPreview} className="image-preview-meta-template" />
                                                    <button
                                                        className="remove-image-button-meta-template"
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                file: null,
                                                            });
                                                            setVideoPreview(null);
                                                        }}
                                                    >
                                                        <Icon icon="fluent:delete-12-filled" style={{ color: "#ffffff" }} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}



                                    {component.example && component.example.body_text && (
                                        <div>
                                            <strong>Example:</strong>
                                            <ul>
                                                {component.example.body_text[0].map((item, idx) => (
                                                    <li key={idx}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className='Flex-Input-Campaigns'>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Select Group</label>
                                <div className='Icon-flex-add'>
                                    <select
                                        className="Query-select-Box"
                                        name="group"
                                        onChange={handleMobngrpChange}
                                        value={selectedGroup}
                                    >
                                        <option value="">--select--</option>
                                        {data.map((group) => (
                                            <option key={group.wa_id} value={group.wa_id}>
                                                {group.Name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='Input-Box-Compaigns'>
                                <label className="Campaigns-lbl">Mobile No</label>
                                <textarea className="Mobile-textarea-input" type="text" name="mobileNo" placeholder="Mobile No"
                                    onChange={handleMobngrpChange}
                                    value={mobileNo} />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", gap: "12px" }}>


                            <div className="view-all-container">
                                <button className="view-all-button" onClick={handleSendButtonClick}>
                                    Send
                                </button>
                            </div>
                            <div className="view-all-container" onClick={handleTemplateDelete}>
                                <button className="view-all-button" style={{ backgroundColor: "red" }} >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default MetaTemplates;
